import { useQuery, useMutation } from "@tanstack/react-query"
import axiosConfig from "./http-common"
import { store } from "./redux/store"
import { loginUserAction } from "./redux/slices/authSlice"





export const useGetMe = (pathname) => useQuery({
  retry: 1,
  queryKey: ["getMe", { pathname }],
  queryFn: async () => {
    try {
      const token = store.getState().auth?.token
      const data = await axiosConfig.get("/auth/getMe")
      console.log(token);
      store.dispatch(loginUserAction({
        accessToken: token,
        user: data?.user
      }))
      return data
    } catch (error) {

    }
  },
  enabled: !!store.getState().auth?.user
})



export const useGetRoles = () => useQuery({
  retry: 1,
  queryKey: ["getRoles"],
  queryFn: async () => await axiosConfig.get("/role")
})


export const useGetBranches = () => useQuery({
  retry: 1,
  queryKey: ["getBranches"],
  queryFn: async () => await axiosConfig.get("/branch")
})


export const useGetBusinessBranches = () => useQuery({
  retry: 1,
  queryKey: ["getBusinessBranches", { branch: store.getState().branch.branch?.business }],
  queryFn: async () => await axiosConfig.get(`/branch/business/${store.getState().branch.branch?.business}/branches`)
})



export const useGetBusinessRoles = () => useQuery({
  retry: 1,
  queryKey: ["getBusinessRoles", { business: store.getState().branch.branch?.business }],
  queryFn: async () => (await axiosConfig.get(`/role/business/${store.getState().branch.branch?.business}`))
})
