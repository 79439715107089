import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { systemName } from "../../helpers/constants/system";
import {
  FaArrowRightLong,
  FaBarsStaggered,
  FaBuilding,
  FaChevronDown,
  FaClock,
} from "react-icons/fa6";
import { Menu } from "@szhsin/react-menu";
import { lockScreen, logoutUser, uuidV4 } from "../../helpers/helperFunctions";
import { FaBell, FaLock, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import moment from "moment";
import CustomReactSelect from "../generic/CustomReactSelect";
import { useDispatch, useSelector } from "react-redux";
import { setBranchAction } from "../../redux/slices/branchSlice";

const notifications = [
  {
    id: uuidV4(),
    message: "You have a new message.",
    timestamp: new Date("2024-03-30T08:00:00"),
    type: "message",
  },
  {
    id: uuidV4(),
    message: "Reminder: Meeting at 10:00 AM.",
    timestamp: new Date("2024-03-30T09:30:00"),
    type: "meeting",
  },
  {
    id: uuidV4(),
    message: "You have a deadline approaching.",
    timestamp: new Date("2024-03-30T12:00:00"),
    type: "deadline",
  },
];

const CustomHeader = ({ isCollapsed, setIsCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);

  return (
    <div className="text-main bg-white p-2 flex items-center justify-between px-5">
      <div className="flex items-center gap-5">
        <button className="" onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? (
            <FaArrowRightLong className="text-xl" />
          ) : (
            <FaBarsStaggered className="text-xl" />
          )}
        </button>
        <Link to={"/dashboard"} className="font-bold">
          {systemName}
        </Link>
      </div>
      <div className="flex items-center gap-7">
        {user && (
          <>
            <h6 className="flex items-center gap-2 font-semibold text-customPrimary">
              <FaBuilding /> <span>{user?.business?.name}</span>
            </h6>

            <Menu
              menuButton={
                <button className="border-0 outline-0 flex items-center gap-2 w-[270px]">
                  <span>{branch ? branch?.label : "Select branch"}</span>{" "}
                  <FaChevronDown />
                </button>
              }
              transition
            >
              <div className="w-[270px] min-h-[150px] p-2">
                <CustomReactSelect
                  placeholder={"Choose a branch"}
                  label={"Choose a branch"}
                  value={branch}
                  onChange={(option) => dispatch(setBranchAction(option))}
                  options={user?.branch?.map((bran) => ({
                    ...bran,
                    label: `${bran?.name} (${bran?.address?.city})`,
                    value: bran?._id,
                  }))}
                />
                <p className="mt-5 text-xs">
                  <b>Note:</b> If you change a branch the data you see and
                  change will only affect that branch. The reason for this
                  design is for efficiency and ease. Imagine seeing data from
                  all at once. Oo boy, that request will take forever to load.
                </p>
              </div>
            </Menu>

            {/* <Menu
              menuButton={
                <button className="border-0 outline-0 relative">
                  <div className="absolute -top-2 -right-3 text-xs p-1 w-[20px] h-[20px] rounded-full bg-orange-500 text-white font-bold flex items-center justify-center">
                    3
                  </div>
                  <FaBell className="text-3xl" />
                </button>
              }
              transition
            >
              <div
                className={"w-[270px] rounded-md overflow-hidden flex flex-col"}
              >
                <div className="bg-secondary flex items-center justify-between p-3">
                  <h6 className="text-white">Notifications</h6>
                  <div className="flex items-center gap-2 bg-white text-black p-1 px-2 rounded-md text-sm font-semibold">
                    4 New
                  </div>
                </div>
                <div className="flex flex-col">
                  {notifications.map((notification) => (
                    <button
                      className="w-full flex flex-col gap-2 p-4 font-semibold hover:bg-gray-100"
                      key={notification.id}
                    >
                      <p>{notification.message}</p>
                      <p className="text-xs flex items-center gap-2 text-gray-500">
                        <FaClock /> {moment(notification.timestamp).fromNow()}
                      </p>
                    </button>
                  ))}
                </div>
                <div className="flex items-center justify-center p-2">
                  <button
                    onClick={() => navigate("/notifications")}
                    className="text-green-800 bg-green-200 p-2 flex items-center gap-2 text-sm "
                  >
                    View All Notifications <FaArrowRightLong />
                  </button>
                </div>
              </div>
            </Menu> */}

            <Menu
              menuButton={
                <button className="border-0 outline-0">
                  <img
                    src="/images/profile.png"
                    className="w-10 h-10 object-scale-down rounded-full"
                    alt="PROFILE"
                  />
                </button>
              }
              shift={12}
              transition
            >
              <div className={"w-[200px] p-2 flex flex-col gap-2"}>
                <p className="text-sm">
                  Welcome{" "}
                  <span className="font-semibold">{`${user?.firstName} ${user?.lastName}`}</span>
                </p>
                <div>
                  <button
                    onClick={() => navigate("/profile")}
                    className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
                  >
                    <FaUserCircle className="text-xl" />{" "}
                    <span>Your Profile</span>
                  </button>
                  <button
                    onClick={() => lockScreen(navigate)}
                    className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
                  >
                    <FaLock className="text-xl" /> <span>Lock screen</span>
                  </button>
                  <button
                    onClick={() => logoutUser(navigate)}
                    className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
                  >
                    <FaSignOutAlt className="text-xl" /> <span>Sign Out</span>
                  </button>
                </div>
              </div>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomHeader;
