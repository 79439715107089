import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: localStorage.getItem("washme-admin-user") ? JSON.parse(localStorage.getItem("washme-admin-user")) : null,
    token: localStorage.getItem("washme-admin-token") ? JSON.parse(localStorage.getItem("washme-admin-token")) : null
  },
  reducers: {
    loginUserAction: (state, action) => {
      console.log(action.payload)
      state.user = action.payload.user;
      state.token = action.payload.accessToken
      localStorage.setItem("washme-admin-user", JSON.stringify(action.payload.user || {}))
      localStorage.setItem("washme-admin-token", JSON.stringify(action.payload.accessToken || ""))

    },
    logoutUserAction: (state) => {
      state.user = null;
      state.token = null
      localStorage.removeItem("washme-admin-user")
      localStorage.removeItem("washme-admin-token")
    }
  }
})

export const { loginUserAction, logoutUserAction } = authSlice.actions

export default authSlice.reducer