import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight, FaMinus } from "react-icons/fa6";
import classNames from "classnames";
import menus from "../../helpers/constants/menus";
import { v4 as uuidV4 } from "uuid";
import CustomSidebarCollapse from "./CustomSidebarCollapse";
import { twMerge } from "tailwind-merge";
import { doesUserHavePermission } from "../../helpers/helperFunctions";
import { Fade } from "react-awesome-reveal";

const CustomSidebar = ({ parentPath, setIsCollapsed, isCollapsed }) => {
  const { pathname } = useLocation();
  const renderChildMenus = (menu) =>
    menu.children.map((childMenu) => (
      <Link
        key={uuidV4()}
        className={classNames(
          "text-gray-200 font-light p-3 hover:text-gray-300 w-full flex items-center gap-3 text-sm px-7",
          pathname === childMenu.link && "text-white"
        )}
        to={childMenu.link}
      >
        <FaMinus className="text-sm" />
        {childMenu.title}
      </Link>
    ));

  return (
    <div
      className={classNames(
        "bg-main relative transition-all duration-300 h-screen flex flex-col",
        isCollapsed ? "w-fit" : "min-w-[300px]"
      )}
    >
      <div className="sticky top-0 bg-main">
        <Link to={"/dashboard"}>
          <img
            src={isCollapsed ? "/images/logo.png" : "/images/logo.png"}
            alt=""
            className={twMerge(
              " object-scale-down m-1 mx-auto",
              isCollapsed ? "w-14 h-14" : "w-28 h-28"
            )}
          />
        </Link>
      </div>

      <div className="mt-5 flex-1 overflow-auto">
        {menus.map((menu) =>
          menu.children ? (
            <CustomSidebarCollapse
              renderChildMenus={renderChildMenus}
              parentPath={parentPath}
              menu={menu}
              key={uuidV4()}
              isCollapsed={isCollapsed}
            />
          ) : (
            doesUserHavePermission(menu.permission) && (
              // <Fade className="w-full">
              <Link
                key={uuidV4()}
                className={classNames(
                  " font-light p-3  w-full flex items-center text-l gap-3 text-lg",
                  menu.link === pathname
                    ? "text-white"
                    : "text-[#cecccc] hover:text-gray-300"
                )}
                to={menu.link}
              >
                {menu.icon} {!isCollapsed && menu.title}
              </Link>
              // </Fade>
            )
          )
        )}
      </div>
    </div>
  );
};

export default CustomSidebar;
