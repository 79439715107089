import { createSlice } from "@reduxjs/toolkit";


const branchSlice = createSlice({
  name: "branch",
  initialState: {
    branch: sessionStorage.getItem("branch") ? JSON.parse(sessionStorage.getItem("branch")) : null,
  },
  reducers: {
    setBranchAction: (state, action) => {
      state.branch = action.payload;
      sessionStorage.setItem("branch", JSON.stringify(action.payload || {}))

    },
    resetBranchAction: (state) => {
      state.branch = null;
      sessionStorage.removeItem("branch")
    }
  }
})

export const { setBranchAction, resetBranchAction } = branchSlice.actions

export default branchSlice.reducer