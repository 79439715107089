import React, { useEffect } from "react";

const PageWrapper = ({ title, children }) => {
  useEffect(() => {
    document.querySelector("title").innerHTML = title || "BEETLE";
  }, [title]);

  return <>{children}</>;
};

export default PageWrapper;
